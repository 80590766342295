import { AsYouType } from 'libphonenumber-js';
import metadata from 'libphonenumber-js/metadata.min.json';

enum ValidationType {
  Email = "Email",
  Phone = "Phone"
}

const validateEmail = (email) => {
  // regex source: http://emailregex.com/
  // valid email syntax source: https://stackoverflow.com/questions/2049502/what-characters-are-allowed-in-an-email-address

  const valid = email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  return valid;
};

const validatePhoneNumber = (phone) => {
  const valid = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/g.test(phone);

  return valid;
};

const formatPhone = (number) => {
  const limit = number.charAt(0) === '1' ? 11 : 10;
  const substring = number.replace(/[\(\)-\s]+/g, '').substring(0, limit);
  const formatted = new AsYouType('US').input(substring);

  return formatted;
};

const validateField = (value, type) => {
  if (type === ValidationType.Email) {
    return validateEmail(value)
  } else {
    return validatePhoneNumber(value);
  }
}

export { validateEmail, validatePhoneNumber, formatPhone, validateField, ValidationType };