import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
const API_URL = "https://api.listwithclever.com"
const ZIP_STATE_LOOKUP_URL = "https://api.zippopotam.us/us/"
export const submitForm = createAsyncThunk(
  'delmar/submitForm',
  async (params: any) => {
    // convert params into query params
    let alteredParams = params;
    if (params.property_zip_code) {
      try {
        const zipLookUpResponse = await axios.get(`${ZIP_STATE_LOOKUP_URL}${params.property_zip_code}`)
        // check places length
        if (zipLookUpResponse.data.places.length) {
          const state = zipLookUpResponse.data.places[0]['state abbreviation']
          alteredParams = {
            ...params,
            state
          }
        } else {
          alteredParams = {
            ...params,
            state: 'MO',
          }
        }
      } catch {
        // do nothing
        alteredParams = {
          ...params,
          state: 'MO',
        }
      }
    }
    
    const queryParams = Object.keys(alteredParams)
      .map((key) => `${key}=${alteredParams[key]}`)
      .join('&');

    try {
      const response = await axios.post(`${API_URL}/contacts/proleads/`, encodeURIComponent(queryParams))
      return response.data;
    } catch (error) {
      return error;
    }
  },
);