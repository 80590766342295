import * as React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { formatPhone, validateEmail, validatePhoneNumber } from '../../utils/validation';
import { submitForm } from './actions';

const LOImage = "https://imgix.cosmicjs.com/d37d7e60-d7db-11ed-8dfd-25924f9a00e0-LO.png";

export enum FormTypes {
  'Refinance' = 'Refinance',
  'Buy' = 'Buy',
  'RefinanceShort' = 'RefinanceShort',
  'BuyShort' = 'BuyShort',
}

export enum FormElement {
  'buttonOptions'= 'buttonOptions',
  'input' = 'input',
  'slider' = 'slider',
  'label' = 'label',
  'button' = 'button',
  'inputGroup' = 'inputGroup',
  'jsx' = 'jsx',
}

const initialState = {
  formType: null as FormTypes | null,
  currentStep: 0,
  formState: {},
  successfullySubmitted: false,
  isSubmitting: false,
  steps: {
    [FormTypes.Refinance]: [
      [{
        type: FormElement.buttonOptions,
        label: "Hey, I'm Tiffany. What type of property are you refinancing?",
        key: 'property_type',
        options: [
          'Single Family Home',
          'Townhome',
          'Condominium',
          'Multifamily Home',
          'Manufactured/Mobile',
        ]
      }],
      [{
        type: FormElement.buttonOptions,
        label: "Why are you refinancing?",
        key: 'refinance_reason',
        options: [
          'Take Cash Out of My Home',
          'Pay off Debts',
          'Rental Property',
        ]
      }],
      [{
        type: FormElement.buttonOptions,
        label: "How will this property be used?",
        key: 'property_usage',
        options: [
          'Primary Home',
          'Secondary Home',
          'Rental Property',
        ],
      }],
      [
        {
          type: FormElement.slider,
          label: "How much do you think your home is worth?",
          key: 'home_value',
          min: 50000,
          max: 2000000,
        },
        {
          type: FormElement.button,
          requirements: ['home_value'],
          label: "Next",
        }
      ],
      [{
        type: FormElement.buttonOptions,
        label: "What's the remaining balance of your mortgage?",
        key: 'remaining_mortgage_balance',
        options: [
          'Less than $20,000',
          '$20,000 - $50,000',
          '$50,000 - $100,000',
          '$100,000 - $150,000',
          '$150,000 - $200,000',
          'More than $200,000',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "What's your credit score?",
        key: 'credit_score',
        options: [
          'Excellent (720+)',
          'Good (680-719)',
          'Fair (580-679)',
          'Poor (Less than 580)',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "Have you had a bankruptcy or foreclosure in the last 7 years?",
        key: 'bankruptcy_or_foreclosure',
        options: [
          'No',
          'Bankruptcy',
          'Foreclosure',
          'Both',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "Have you or your spouse served in the US military?",
        subtitle: "Active-duty military and Veterans may qualify for a VA Loan with $0 down and no PMI.",
        key: 'military_status',
        options: [
          'No Military Service',
          'Yes, I or my spouse served',
        ],
      }],
      [
        {
          type: FormElement.label,
          key: 'name',
          label: "What is your name?",
        },
        {
          type: FormElement.inputGroup,
          key: 'name_group',
          inputs: [
            {
              type: FormElement.input,
              label: "First",
              key: 'first_name',
            },
            {
              type: FormElement.input,
              label: "Last",
              key: 'last_name',
            },
          ]
        },
        {
          type: FormElement.button,
          requirements: ['first_name', 'last_name'],
          label: "Next",
        }
      ],
      [
        {
          type: FormElement.label,
          key: 'email_address',
          label: "What is your email address?",
        },
        {
          type: FormElement.input,
          label: "Email",
          key: 'email',
        },
        {
          type: FormElement.button,
          requirements: [
            'email',
            (FormData) => validateEmail(FormData['email'])
          ],
          label: "Next",
        }
      ],
      [
        {
          type: FormElement.label,
          key: 'Zip Code',
          label: "What is your property zip code?",
        },
        {
          type: FormElement.input,
          label: "Property Zip Code",
          key: 'zip',
        },
        {
          type: FormElement.button,
          requirements: [
            'zip',
          ],
          label: "Next",
        }
      ],
      [
        {
          type: FormElement.label,
          key: 'phone_number',
          label: "What's the best number to reach you?",
        },
        
        {
          type: FormElement.input,
          label: "Phone",
          key: 'phone',
          inputType: 'tel',
          inputFormatter: (value) => formatPhone(value),
        },
        {
          type: FormElement.button,
          label: "Compare Rates",
          requirements: [
            'phone',
            (FormData) => validatePhoneNumber(FormData['phone']),
          ]
        }
      ],
    ],
    [FormTypes.Buy]: [
      [{
        type: FormElement.buttonOptions,
        label: "Hey, I'm Tiffany. What type of property are you buying?",
        key: 'property_type',
        options: [
          'Single Family Home',
          'Townhome',
          'Condominium',
          'Multifamily Home',
          'Manufactured/Mobile',
        ]
      }],
      [{
        type: FormElement.buttonOptions,
        label: "How will you use this property?",
        key: 'property_usage',
        options: [
          'Primary Home',
          'Secondary Home',
          'Rental Property',
        ]
      }],
      [{
        type: FormElement.buttonOptions,
        label: "Have you found your new home already?",
        key: 'shopping_status',
        options: [
          'Yes',
          'No',
        ],
      }],
      [
        {
          type: FormElement.label,
          key: 'property_location',
          label: "Where is the property located?",
        },
        {
          type: FormElement.input,
          label: "Property Zip Code",
          key: 'zip',
        },
        {
          type: FormElement.button,
          requirements: ['zip'],
          label: "Next",
        }
      ],
      [{
        type: FormElement.buttonOptions,
        label: "Are you working with a real estate agent currently?",
        key: 'working_with_agent',
        options: [
          'Yes',
          'No',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "When do you plan on buying a home?",
        key: 'buying_timeframe',
        options: [
          'Already under contract',
          'Immediately',
          'I am shopping around now',
          'Within next couple months',
          'Not sure',
        ]
      }],
      [{
        type: FormElement.buttonOptions,
        label: "Are you also selling a house or property?",
        key: 'selling_property',
        options: [
          'Yes',
          'No',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "What's your budget for your new home?",
        key: 'budget',
        options: [
          'Under $50,000',
          '$50,000 - $100,000',
          '$100,000 - $150,000',
          '$150,000 - $200,000',
          '$200,000 - $250,000',
          '$250,000 - $300,000',
          '$300,000 - $350,000',
          '$350,000 - $400,000',
          '$400,000 or more',
        ],
      }],
      [
        {
          type: FormElement.label,
          key: 'the_down_payment',
          label: "How much money are you putting towards the down payment?",
        },
        {
          type: FormElement.jsx,
          jsx: (<p className="text-center">(An estimate is fine)</p>)
        },
        {
          type: FormElement.buttonOptions,
          label: "",
          key: 'down_payment',
          options: [
            '3.5%',
            '5%',
            '10%',
            '15%',
            '20%',
            'More than 20%',
          ]
        }
      ],
      [{
        type: FormElement.buttonOptions,
        label: "What is your current employment status?",
        key: 'employment_status',
        stack: 'vertical',
        options: [
          'Employed',
          'Self-Employed / 1099 Independent Contractor',
          'Retired',
          'Not Employed',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "What is your household gross annual income (before taxes)?",
        key: 'income',
        options: [
          'Less than $30,000',
          '$30,000 - $50,000',
          '$50,000 - $75,000',
          '$75,000 - $100,000',
          'More than $100,000',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "What is your credit score?",
        key: 'credit_score',
        options: [
          'Excellent (720+)',
          'Good (680-719)',
          'Fair (620-679)',
          'Poor (619 or less)',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "Have you had a bankruptcy or foreclosure in the last 7 years?",
        key: 'bankruptcy',
        options: [
          'No',
          'Bankruptcy',
          'Foreclosure',
          'Both',
        ],
      }],
      [{
        type: FormElement.buttonOptions,
        label: "Have you or your spouse ever served in the US military?",
        key: 'military_status',
        options: [
          'No military service',
          'Yes, I or my spouse served',
        ],
      }],
      [
        {
          type: FormElement.label,
          label: "What is your name?",
          key: "name"
        },
        {
          type: FormElement.inputGroup,
          inputs: [
            {
              type: FormElement.input,
              label: "First Name",
              key: 'first_name',
            },
            {
              type: FormElement.input,
              label: "Last Name",
              key: 'last_name',
            },
          ]
        },
        {
          type: FormElement.button,
          requirements: ['first_name', 'last_name'],
          label: "Next",
        }
      ],
      [
        {
          type: FormElement.label,
          key: 'email_address',
          label: "What is your email address?",
        },
        {
          type: FormElement.input,
          label: "Email",
          key: 'email',
        },
        {
          type: FormElement.button,
          requirements: [
            'email',
            (FormData) => validateEmail(FormData['email'])
          ],
          label: "Next",
        }
      ],
      [
        {
          type: FormElement.label,
          key: "phone_number",
          label: "What's the best number to reach you?",
        },
        {
          type: FormElement.input,
          label: "Phone Number",
          inputFormatter: (value) => formatPhone(value) || '',
          key: 'phone',
        },
        {
          type: FormElement.button,
          requirements: [
            'phone',
            (FormData) => validatePhoneNumber(FormData['phone']),
          ],
          label: "Compare Rates",
        }
      ],
    ],
    [FormTypes.RefinanceShort]: [
      [{
        type: FormElement.buttonOptions,
        label: "What's your credit score?",
        key: 'credit_score',
        options: [
          'Excellent (720+)',
          'Good (680-719)',
          'Fair (620-679)',
          'Poor (619 or less)',
        ],
      }],
      [
        {
          type: FormElement.label,
          key: 'contact_information',
          label: "Where should we send you information about rates?",
        },
        {
          type: FormElement.inputGroup,
          key: 'information',
          stack: 'vertical',
          inputs: [
            {
              type: FormElement.input,
              label: "Name",
              key: 'name',
            },
            {
              type: FormElement.input,
              label: "Email Address",
              key: 'email',
            },
            {
              type: FormElement.input,
              label: "Phone Number",
              inputFormatter: (value) => formatPhone(value),
              key: 'phone',
            },
            {
              type: FormElement.input,
              label: "Property Zip Code",
              key: 'zip',
            }
          ]
        },
        {
          type: FormElement.button,
          requirements: [
            'name',
            'email',
            'phone',
            'zip',
            (FormData) => validatePhoneNumber(FormData['phone']),
            (FormData) => validateEmail(FormData['email'])
          ],
          label: "Compare Rates",
        }
      ],
    ],
    [FormTypes.BuyShort]: [
      [{
        type: FormElement.buttonOptions,
        label: "What's your credit score?",
        key: 'credit_score',
        options: [
          'Excellent (720+)',
          'Good (680-719)',
          'Fair (620-679)',
          'Poor (619 or less)',
        ],
      }],
      [
        {
          type: FormElement.label,
          key: 'contact_information',
          label: "Where should we send you information about rates?",
        },
        {
          type: FormElement.inputGroup,
          key: 'information',
          stack: 'vertical',
          inputs: [
            {
              type: FormElement.input,
              label: "Name",
              key: 'name',
            },
            {
              type: FormElement.input,
              label: "Email Address",
              key: 'email',
            },
            {
              type: FormElement.input,
              label: "Phone Number",
              inputFormatter: (value) => formatPhone(value),
              key: 'phone',
            },
            {
              type: FormElement.input,
              label: "Property Zip Code",
              key: 'zip',
            }
          ]
        },
        {
          type: FormElement.button,
          requirements: [
            'name',
            'email',
            'phone',
            'zip',
            (FormData) => validatePhoneNumber(FormData['phone']),
            (FormData) => validateEmail(FormData['email'])
          ],
          label: "Compare Rates",
        }
      ],
    ],
  },
};

const delmarSlice = createSlice({
  name: 'delmar',
  initialState,
  reducers: {
    resetState: (state) => {
      state.formType = null as FormTypes | null
      state.currentStep = 0
      state.formState = {}
      state.successfullySubmitted = false
      state.isSubmitting = false
    },
    selectFormType: (state, { payload }) => {
      state.formType = payload;
    },
    nextStep: (state) => {
      state.currentStep += 1;
    },
    prevStep: (state) => {
      state.currentStep -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitForm.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(submitForm.fulfilled, (state, payload) => {
      state.isSubmitting = false;
      state.successfullySubmitted = true;
    });
    builder.addCase(submitForm.rejected, (state, payload) => {
      state.isSubmitting = false;
      state.successfullySubmitted = false;
    });
  },
});

export const { nextStep, prevStep, selectFormType, resetState } = delmarSlice.actions;

export default delmarSlice.reducer;